import service from "../service.js";

export const searchUserData = params => {
    return service({
      method: 'POST',
      url: '/user/searchUser',
      params
    })
}

export const delUser = (account) => {
  return service({
    method: 'GET',
    url: '/user/deleteUser',
    params: {
      account
    }
  })
}

export const resetUserPass = (account,password) => {
  return service({
    method: 'POST',
    url: '/user/resetPassword',
    params: {
      account,
      password
    }
  })
}

export const queryUser = (account) => {
  return service({
    method: 'POST',
    url: '/user/queryUser',
    params: {
      account
    }
  })
}

export const addUser = (data) => {
  return service({
    method: 'POST',
    url: '/user/addUser',
    data
  })
}

export const updateUser = (data) => {
  return service({
    method: 'POST',
    url: '/user/editUser',
    data
  })
}

export const getUserById = (userId) => {
  return service({
    method: 'GET',
    url: '/user/getUserById',
    params: {
      userId
    }
  })
}